<template>
    <el-form
        ref="defaultAccount"
        size="mini"
        :model="defaultAccounts"
        label-width="250px"
        class="border border-company-blue rounded self-start relative pt-2 pl-2 pr-2"
        label-position="right"
        v-loading="$waiting.is('defaultAccountloading')"
    >
        <header class="font-semibold mb-2 text-header-blue">Standardkonto för leverantören</header>
        <el-form-item class="font-thin" label="Aktiebolag Konto">
            <AccountsPlanItems
                class="rounded border border-solid border-input-border"
                :accounts-data="accountPlanItems"
                @selected="ltdAccountSelected"
                :pre-selected-account="defaultAccounts.aktiebolagKonto"
            />
        </el-form-item>
        <el-form-item class="font-thin" label="Övriga bolagsformer Konto">
            <AccountsPlanItems
                class="rounded border border-solid border-input-border"
                :accounts-data="accountPlanItems"
                @selected="accountSelected"
                :pre-selected-account="defaultAccounts.othersAccount"
            />
        </el-form-item>
        <el-form-item>
            <el-button @click="closeSection()">Avbryt</el-button>
            <el-button type="primary" :disabled="disableSubmit" @click="onSubmit">Spara</el-button>
        </el-form-item>
    </el-form>
</template>
<script>
import Api from "./documents.api";

export default {
    props: {
        accountPlanItems: {
            type: Array,
            default: () => [],
        },

        preSelectedLtdAccount: {
            type: Number,
            default: 0,
        },

        preSelectedPrivateAccount: {
            type: Number,
            default: 0,
        },

        supplierId: {
            type: Number,
            default: 0,
        },
    },

    data() {
        return {
            defaultAccounts: { aktiebolagKonto: this.preSelectedLtdAccount, othersAccount: this.preSelectedPrivateAccount },
        };
    },

    components: {
        AccountsPlanItems: () => import(/* webpackChunkName: "AccountsPlanItems" */ "@/components/vouchersTable/v1/AccountsPlanItems.vue"),
    },

    methods: {
        ltdAccountSelected(account) {
            this.defaultAccounts.aktiebolagKonto = account;
        },

        accountSelected(account) {
            this.defaultAccounts.othersAccount = account;
        },

        async onSubmit() {
            this.$waiting.start("defaultAccountloading");
            await Api.updateSupplierDefaultAccounts(this.supplierId, this.defaultAccounts);
            this.$notify.success({ title: "Utfört" });
            this.$waiting.end("defaultAccountloading");
            this.$emit("success", { ltdDefaultAcc: this.defaultAccounts.aktiebolagKonto, privateDefaultAccount: this.defaultAccounts.othersAccount });
        },

        closeSection() {
            this.$emit("close");
        },
    },

    computed: {
        disableSubmit() {
            return !this.defaultAccounts.aktiebolagKonto && !this.defaultAccounts.othersAccount ? true : false;
        },
    },
};
</script>
